import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "Node[Interface] | Sako ";
export const IMAGES_SERVER = "https://static.sako.ooo";
export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 3002,
    Michelin: 3003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];
export const PROXY_DATABOX_URL = "https://proxy-data-box.ia.parts";
export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://www.eurorepar.fr/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://back.ia.parts/",
    diag: "https://box.ia.parts/",
    erp: "https://cloudio.sako.ooo",
    store: "https://appstore.ia.parts",
    visio: "https://meetbox.ia.parts",
    com: "https://com.ia.parts/",
    help: "https://uniflix.ia.parts/",
    tecrmi: "https://rmi.ia.parts",
};

export const iframePartSelector = {
    fr: "https://sako.ooo",
    en: "https://sako.ooo",
    cz: "https://sako.ooo",
    zh: "https://sako.ooo",
    de: "https://sako.ooo",
};

export const iframeCarSelector = {
    fr: "https://cloudio.sako.ooo",
    en: "https://cloudio.sako.ooo",
    cz: "https://cloudio.sako.ooo",
    zh: "https://cloudio.sako.ooo",
    de: "https://cloudio.sako.ooo",
};

export const iframeContactSelector = {
    fr: "https://cloudio.sako.ooo",
    en: "https://cloudio.sako.ooo",
    cz: "https://cloudio.sako.ooo",
    zh: "https://cloudio.sako.ooo",
    de: "https://cloudio.sako.ooo",
};

export const iframeStatsSelector: Record<string, string> = {
    fr: "https://sako.ooo",
    en: "https://sako.ooo",
    cz: "https://sako.ooo",
    zh: "https://sako.ooo",
    de: "https://sako.ooo",
};

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://sakopower.com" },
    { name: "C@iA", url: "https://sakopower.com" },
    { name: "C@iA", url: "https://sakopower.com" },
    { name: "C@iA", url: "https://sakopower.com" },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://sakopower.com",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://sakopower.com",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://sakopower.com",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const WORDPRESS_URL = "https://sako.ooo";
type RseSlide = { iframeURL?: string; imgSrc: string };

const WP_CONTENT_PATH = "https://sako.ooo/wp-content/uploads";
export const RSE_SLIDES: RseSlide[] = [
    {
        iframeURL: "https://sako.ooo",
        imgSrc: `${WP_CONTENT_PATH}/2021/09/EMAIL-PARTIE-MARKETING-HUB-optimisee-web.jpg`,
    },
    {
        iframeURL:
            "https://wp.n.parts/idlp/?r3d=le-magby-groupe-idlp-novembre-2021",
        imgSrc: `${WP_CONTENT_PATH}/2021/09/LOGO-visuel-mag-apps.png`,
    },
    {
        iframeURL:
            "https://wp.n.parts/idlp/?r3d=produits-saison-hiver-2021-2022",
        imgSrc: `${WP_CONTENT_PATH}/2021/09/PRODUIT-SAISON-HIVER-2021-2022-optimisee-web.jpg`,
    },
];

export const USE_KEYCLOAK = true;

export const CART_TABS = [];

export const LEVAM_URL = "https://oem1.ia.parts/oem_idlp.html";

export const showWPSearchBar = false;
